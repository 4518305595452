.side-content {
  position: relative;
  z-index: 1200;
  padding-top: 20px;
}

.side-content-download {
  position: relative;
}

.side-content-download .download-section {
  margin: 80px 0;
}

.side-content-download h4 {
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
}

.side-content-download .download-mobile {
  width: 140px;
  margin-top: 10px;
}

.side-content-download .download-on-store {
  margin-top: 5px;
  text-align: center;
}

.side-content-download .download-on-store img {
  margin-top: 10px;
  width: 140px;
}
