form .upload-pic-holder {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  align-items: center;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 45px;
}

form .upload-pic-holder img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
}

form .upload-pic-holder button {
  font-size: 28px;
  font-weight: 300;
  color: rgba(255, 255, 255, 1);
  line-height: 1.4;
  margin: 0;
  background: transparent;
}

form .places--root {
  position: relative;
  padding-bottom: 0;
}

form .places--autocomplete-container {
  position: absolute;
  top: 100%;
  background-color: #ffffff;
  width: 100%;
  border: 1px solid #b1b1b1;
}

form .places--autocomplete-item {
  background-color: #ffffff;
  padding: 10px;
  color: #555555;
  cursor: pointer;
  font-size: 12px;
}

form .places--autocomplete-item-active {
  background-color: #ededed;
}

form .places--google-logo-container {
  text-align: right;
  padding: 1px;
}

form .places--google-logo-image {
  width: 80px;
}

.phone-auth-box form h1 {
  display: none;
  font-size: 20px;
  font-weight: 300;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 16px;
}

.phone-auth-box form {
  width: 100%;
  text-align: left;
}

.phone-auth-box form label {
  display: block;
  margin-bottom: 2px;
  color: rgba(255, 255, 255, 1);
  font-weight: 300;
  font-size: 14px;
  margin-top: 20px !important;
}

.phone-auth-box form input {
  width: 97%;
  color: rgba(120, 120, 120, 1);
  font-size: 12px;
  border: none;
  padding: 6px !important;
  line-height: 1.4;
}

.phone-auth-box form .form-actions button {
  display: inline-block;
}

.phone-auth-box form .grecaptcha-badge {
  display: none !important;
}

.phone-auth-box form .firebaseui-card-footer p {
  font-size: 11px;
  color: rgba(255, 255, 255, 1);
  margin-top: 8px;
}

.phone-auth-box form .firebaseui-form-actions {
  display: flex;
  justify-content: space-between;
}

.phone-auth-box button {
  background: rgba(90, 54, 140, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 13px;
  padding-left: 30px;
  padding-right: 30px;
}

.phone-auth-box p.firebaseui-error,
.firebaseui-text,
.firebaseui-resend-container span {
  font-size: 11px;
  color: rgba(216, 255, 14, 1);
  margin-top: 8px;
}

.phone-auth-box .firebaseui-id-secondary-link {
  display: none !important;
}

.firebaseui-text,
.firebaseui-resend-container span {
  font-size: 13px;
  margin-right: 10px;
}

.firebaseui-resend-container a {
  font-size: 12px;
  text-transform: uppercase;
}

.firebaseui-dialog {
  border: none;
  top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.firebaseui-list-box-actions {
  max-height: 530px;
  max-width: 800px;
  overflow: auto;
}

.firebaseui-list-box-actions button {
  font-size: 12px;
  cursor: pointer;
  width: 250px;
  border: none;
  border-radius: 4px;
  padding: 8px 18px;
  margin: 5px;
  background: rgba(245, 245, 245, 1);
}
