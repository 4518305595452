html,
body {
  font-size: 16px;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
}

a {
  color: rgba(0, 0, 0, 0.54);
  text-decoration: none;
  transition: color ease-in-out 0.2s;
}

a:hover {
  color: rgba(0, 0, 0, 0.92);
  text-decoration: none;
}

input:focus {
  outline: none;
}

input[disabled] {
  background: rgba(235, 235, 235, 1);
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.m-t-30 {
  /* margin-top: 30px; */
}

.m-t-60 {
  /* margin-top: 60px; */
  padding: 2%;
}

.hidden {
  display: none !important;
}

input.input-sm {
  width: 50% !important;
}

input.input-xs {
  width: 10% !important;
  margin-right: 20px;
  margin-left: 0;
}

.input-value {
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
}

.input-value a {
  border-bottom: 1px dotted;
}

.logo-holder {
  display: none;
  margin-top: 30px;
  position: fixed;
}

@media (min-width: 62em) {
  .logo-holder {
    display: block;
  }
}

.logo-holder a {
  border: none;
}

.logo-holder img {
  width: 40px;
}

#ujama,
.screen-container,
.screen-content {
  width: 100%;
}

#main-appbar {
  height: 64px;
  padding-top: 8px;
  box-shadow: 0px 3px 8px #00000010;
  z-index: 9999;
}

#main-appbar.mobile {
  padding-top: 8px;
  flex-grow: 0;
  top: auto;
  bottom: -1px;
  background: white;
  overflow: hidden;
}

#top-anchor {
  float: 'left';
  clear: 'both';
}

#main-layout {
  flex-grow: 1;
}

@media (min-width: 960px) {
  #main-sidebar {
    padding: 0 24px;
    max-height: 100vh;
  }
}

.screen-content {
  min-height: 100vh;
  background: #f7f7f7;
}

::-webkit-input-placeholder {
  color: rgba(210, 210, 210, 1);
  font-size: 11px;
}

::-moz-placeholder {
  color: rgba(210, 210, 210, 1);
  font-size: 11px;
}

:-ms-input-placeholder {
  color: rgba(210, 210, 210, 1);
  font-size: 11px;
}

:-moz-placeholder {
  color: rgba(210, 210, 210, 1);
  font-size: 11px;
}

.text-center {
  text-align: center;
}

button {
  border: none;
  border-radius: 18px;
  padding: 5px 18px;
  font-size: 15px;
  cursor: pointer;
}

button.primary {
  background: rgba(90, 54, 140, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 13px;
}

button.primary[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}

button.danger {
  background: rgba(255, 49, 59, 1);
}

img.round {
  border-radius: 50%;
  object-fit: cover;
}

.loading {
  padding: 20px 0 50px;
}

.loading:after {
  content: '';
  position: absolute;
  height: 50px;
  width: 50px;
  margin: auto;
  animation: rotation 0.8s infinite linear;
  border: 7px solid #00bbd436;
  border-top-color: #01c6d9;
  border-radius: 100%;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

#main-content {
  max-width: 960px;
  height: 100%;
  margin: 0 auto;
  padding: 24px 0 0;
  background: #fff;
  text-align: center;
}

#main-content.full-height {
  height: 100vh;
  display: flex;
  align-items: center;
}

#main-content .logo {
  font-size: 24px;
}

#main-content .logo img {
  width: 200px;
}

form {
  margin-top: 20px;
  padding: 2%;
}

form .form-elements {
  text-align: left;
}

form ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

form ul li.field {
  margin-bottom: 15px;
}

form ul li.field.required label:after {
  content: '*';
  margin-left: 2px;
  font-size: 12px;
  color: rgba(255, 202, 132, 1);
}

form ul li.field div.input-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

form ul li.field div.input-group span {
  color: rgba(255, 255, 255, 1);
  align-self: center;
  font-size: 18px;
}

form ul li.field label {
  display: block;
  margin-bottom: 2px;
  color: rgba(255, 255, 255, 1);
  font-weight: 300;
  font-size: 14px;
}

form ul li.field input {
  width: 100%;
  color: rgba(120, 120, 120, 1);
  font-size: 12px;
  border: none;
  padding: 6px !important;
  line-height: 1.4;
}

form ul li.field input:focus {
  outline: none;
}

form p.help-text {
  font-size: 11px;
  color: rgba(255, 255, 255, 1);
  margin-top: 8px;
}

form p.help-text span {
  color: rgba(255, 202, 132, 1);
}

form .form-actions {
  color: rgba(255, 255, 255, 1);
  margin-top: 20px;
  font-size: 12px;
}

form .form-actions input[type='checkbox'] {
  border: none;
  margin-right: 5px;
}

form .form-actions a {
  color: rgba(255, 255, 255, 1);
}

form .form-actions .actions {
  margin-top: 40px;
}

#main-content .content-panel {
  width: 100%;
}
#main-content .content-panel,
#main-content .list-panel {
  margin-top: 30px;
}

.screen-holder {
  padding: 15px;
}

.screen-holder textarea::placeholder {
  font-size: 14px;
}

.screen-holder textarea:focus {
  outline: none;
}

.screen-holder.loading:before {
  content: '';
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 99;
  left: 0;
  top: 0;
}

.screen-holder.loading:after {
  top: 50%;
  left: 50%;
  z-index: 999;
}

.screen-holder > section {
  padding: 0 6px 36px;
}

.content-panel h3,
.list-panel h3 {
  font-size: 28px;
  font-weight: 300;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 5px;
  color: black;
}

.content-panel .entity-list,
.content-panel .entity-content {
  background: rgba(255, 255, 255, 0.9);
  padding: 15px 15px;
  text-align: left;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-panel .entity-list .no-records,
.entity-list-view .no-records {
  text-align: center;
  color: rgba(130, 130, 130, 1);
  font-size: 14px;
  line-height: 1.4;
}

.content-panel .entity-list.loading:after {
  position: relative;
}

.entity-list.overlay-list {
  min-height: 70px;
}

.content-panel .entity-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.content-panel .entity-list ul li {
  display: inline-block;
  margin-right: 15px;
}

.content-panel .entity-list ul li a {
  display: block;
}

.content-panel .actions {
  margin-top: 15px;
}

.named-entity {
  width: 90px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.named-entity .overlay-entity {
  position: relative;
  width: 100%;
  height: 90px;
}

.named-entity img {
  height: 90px;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.named-entity h6 {
  font-size: 10px;
  color: rgba(100, 100, 100, 1);
  line-height: 1.4;
  margin: 5px 0 0;
  font-weight: 600;
}

.named-entity h6.overlay-text {
  position: absolute;
  display: flex;
  color: rgba(255, 255, 255, 1);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.65);
  margin: 0;
  justify-content: center;
}

.named-entity h6.overlay-text span {
  align-self: center;
}

.entity-profile {
  margin-top: 10px;
  color: rgba(255, 255, 255, 1);
}

.entity-profile .profile-detail {
  min-height: 220px;
  color: black;
}

.entity-profile .profile-detail img {
  width: 80px;
  height: 80px;
}

.entity-profile .profile-detail h4 {
  font-size: 28px;
  font-weight: 300;
  margin: 15px 0 10px;
}

.entity-profile .profile-detail p {
  margin: 0;
  font-size: 13px;
}

.entity-profile .profile-detail p.info-text {
  margin-bottom: 10px;
}

.entity-profile .profile-detail p.entity-value-pair {
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 10px;
}

p.entity-value-pair.smaller {
  font-size: 14px !important;
}

.list-panel .list-search {
  margin-top: 20px;
}

.list-panel .list-search input {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 350px;
  background: rgba(255, 255, 255, 1);
  border-radius: 2px;
  padding: 8px 10px;
  color: rgba(30, 30, 30, 0.6);
  font-size: 14px;
  text-align: left;
}

.list-panel .list-search input::-webkit-input-placeholder {
  color: rgba(120, 120, 120, 0.6);
  font-size: 14px;
  text-align: left;
}

.list-panel .list-search input::-moz-placeholder {
  color: rgba(120, 120, 120, 0.6);
  font-size: 14px;
  text-align: left;
}

.list-panel .list-search input:-ms-input-placeholder {
  color: rgba(120, 120, 120, 0.6);
  font-size: 14px;
  text-align: left;
}

.list-panel .list-search input:-moz-placeholder {
  color: rgba(120, 120, 120, 0.6);
  font-size: 14px;
  text-align: left;
}

.entity-list-holder {
  padding: 8px 16px;
  background: rgba(255, 255, 255, 1);
  width: 60%;
  margin: 20px auto;
}

.entity-list-holder-large {
  padding: 8px 16px;
  background: rgba(255, 255, 255, 1);
  width: 80%;
  margin: 20px auto;
}

.entity-list-view {
  height: 400px;
  overflow: auto;
  padding: 16px;
}

.entity-list-view .container-fluid {
  padding: 0;
}

.entity-list-view::-webkit-scrollbar {
  width: 6px;
}

/* .entity-list-view::-webkit-scrollbar-track {
    background: rgba(200, 200, 200, 1);
    border: 4px solid transparent;
    background-clip: content-box;
}

.entity-list-view::-webkit-scrollbar-thumb {
    background: rgba(216, 106, 43, 0.9);
} */

.entity-list-view ul {
  list-style: none;
  text-align: left;
  margin: 0;
  padding: 0;
}

.entity-list-view ul li {
  margin-bottom: 15px;
}

.entity-list-view ul li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.entity-list-view ul li img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
  object-fit: cover;
  font-size: 10px;
  text-overflow: ellipsis;
}

.entity-list-view .list-named-entity span {
  color: rgba(100, 100, 100, 1);
  font-size: 13px;
}

.entity-list-view .list-label {
  background: rgba(90, 54, 140, 1);
  color: rgba(255, 255, 255, 1);
  border-radius: 18px;
  padding: 5px 18px;
  font-size: 10px;
}

.footer-text {
  margin-top: 40px;
  margin-bottom: 10px;
  padding-right: 15px;
  padding-left: 15px;
  color: #9e9e9e;
  font-size: 12px;
  text-align: center;
  line-height: 1.4;
}

/* Toast Styles */
.toast-message {
  bottom: 50px;
  font-size: 13px;
  padding: 6px 16px;
}

/* Alphabetical Groups */
.group-container {
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
}

ul.alphabetical-group {
  text-align: right;
  position: absolute;
}

ul.alphabetical-group li {
  font-size: 13px;
  color: rgba(180, 180, 180, 1);
  font-weight: 300;
  margin-bottom: 6px;
  text-transform: uppercase;
}

.screen-holder .MuiGrid-root,
.screen-holder .MuiGrid-item,
.screen-holder .MuiListItem-root {
  text-align: left;
}

.tabicon-active * {
  stroke: #01c6d9 !important;
}

.tabicon-active .svg-icon-messages-a,
.tabicon-active .svg-icon-messages-d {
  fill: #01c6d9 !important;
  stroke: none !important;
}
.tabicon-active .svg-icon-messages-b,
.tabicon-active .svg-icon-messages-c {
  stroke: none !important;
}

.tab-unread {
  transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
}
.tab-unread::after,
.unreadBubble {
  content: '';
  display: inline-block;
  background: #ff4568;
  border-radius: 100px;
  height: 10px;
  width: 10px;
  position: absolute;
  transform: translate(7px, -10px);
}

.MuiTab-root .unreadBubble {
  transform: translate(36px, 0);
}

#navigation-dropdown {
  height: auto;
  cursor: pointer;
  user-select: none;
}

.unreadMessageCount {
  width: 20px;
  height: 20px;
  color: #ffffff;
  display: inline-block;
  border-radius: 100px;
  background: #ff4568;
  text-align: center;
  line-height: 22px;
  font-size: 14px;
  margin: 14px 0 0;
}

.unreadMessageCount.MuiBadge-root {
  background: none;
  position: absolute;
  top: 0;
  right: 10px;
}
.unreadMessageCount .MuiBadge-badge {
  background: #ff4568;
  transform: none;
}

/* temporary for listitems used as buttons */
.MuiList-root.clickable-list-items .MuiListItem-root,
.MuiListItem-root.clickable {
  cursor: pointer;
  transition: background-color ease-in 0.2s;
}
.MuiList-root.clickable-list-items .MuiListItem-root:hover,
.MuiListItem-root.clickable:hover {
  background-color: #fafafa;
}

/* home ui */
.MuiListItem-root.responsive-card {
  min-width: 147px;
  height: 200px;
  margin: 0 1em;
  padding: 0;
  position: relative;
  cursor: pointer;
  transition: opacity ease-in 0.2s;
  border-radius: 16px;
  align-items: flex-end;
}

.MuiListItem-root.responsive-card .MuiTypography-h3,
.MuiListItem-root.responsive-card p.MuiTypography-root {
  width: 100%;
  color: white;
  z-index: 10;
  padding: 14px;
  margin: 0;
}

.MuiListItem-root.responsive-card .MuiTypography-h3 {
  font-size: 14px;
}

.MuiListItem-root.responsive-card p.MuiTypography-root {
  text-align: right;
}

.MuiListItem-root.responsive-card .MuiCard-root:after {
  content: '';
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  bottom: 0;
  right: 0;
  display: inline-block;
  background: transparent linear-gradient(181deg, #12121200 0%, #121212 73%, #121212 100%) 0% 0%
    no-repeat padding-box;
  opacity: 0;
  border-radius: 16px;
  animation: fadeinCardsShadowOverlay 4s;
  animation-fill-mode: forwards;
  transition: opacity ease-in 0.2s;
}
@keyframes fadeinCardsShadowOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.32;
  }
}

.MuiListItem-root.responsive-card .MuiCard-root {
  display: flex;
  align-items: flex-end;
}
.MuiListItem-root.responsive-card .MuiCard-root,
.MuiListItem-root.responsive-card .MuiCard-root img {
  min-width: 147px;
  min-height: 200px;
  position: absolute;
  top: 0;
}

.MuiListItem-root.responsive-card .MuiCard-root img {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  transition: opacity ease-in 0.2s;
}

.MuiListItem-root.responsive-card:hover,
.MuiListItem-root.responsive-card:hover img,
.MuiListItem-root.responsive-card:hover:after {
  opacity: 0.75;
}

@media (min-width: 400px) {
  .MuiListItem-root.responsive-card {
    min-width: 205px;
    height: 280px;
  }
  .MuiListItem-root.responsive-card .MuiCard-root,
  .MuiListItem-root.responsive-card img {
    width: 205px;
    height: 280px;
  }
  .MuiListItem-root.responsive-card .MuiTypography-h3 {
    font-size: 16px;
  }
}

@media (min-width: 600px) {
  .MuiListItem-root.responsive-card {
    min-width: 235px;
    height: 320px;
  }

  .MuiListItem-root.responsive-card .MuiCard-root,
  .MuiListItem-root.responsive-card img {
    width: 235px;
    height: 320px;
  }
  .MuiListItem-root.responsive-card .MuiTypography-h3 {
    font-size: 20px;
  }
}

@media (min-width: 960px) {
  .MuiListItem-root.responsive-card {
    min-width: 275px;
    height: 374px;
  }

  .MuiListItem-root.responsive-card .MuiCard-root,
  .MuiListItem-root.responsive-card img {
    width: 275px;
    height: 374px;
  }
  .MuiListItem-root.responsive-card .MuiTypography-h3 {
    font-size: 24px;
  }
}

.account-options .MuiButtonBase-root {
  font-size: 16px;
  font-weight: 600;
  padding: 12px 36px 8px 24px;
}

.account-options .MuiButtonBase-root:first-child {
  padding-top: 14px;
}

.account-options .MuiButtonBase-root:last-child {
  padding-bottom: 14px;
}

.account-options svg {
  width: 24px;
  margin: 0 7px 0 0;
}

.marketplace-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  cursor: pointer;
  max-width: 300px;
  border-radius: 20px;
  padding: 10px;
}
.marketplace-item:hover {
  background-color: #f2f4f4;
}

#footer {
  text-align: center;
}

/* chat-bot */
.chat-bot-container {
  height: calc(100vh - 290px);
  min-height: 300px;
  position: relative;
}

@media (min-width: 600px) {
  .chat-bot-container {
    height: calc(100vh - 246px);
    min-height: 320px;
  }
}

.chat-bot-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: 820px;
  max-height: 170px;
  overflow-y: auto;
}

@media (min-width: 600px) {
  .chat-bot-footer {
    max-height: auto;
    overflow-y: unset;
  }
}
/* chat */
#chat-pane .rowLeft,
#chat-pane .rowRight {
  padding: 0;
}

#chat-pane .rowLeft .bubble,
#chat-pane .rowRight .bubble {
  padding: 14px;
  margin: 0px;
  color: #000;
  font-size: 14px;
  letter-spacing: 0.07px;
  line-height: 20px;
  font-weight: 400;
  max-width: 100%;
  cursor: default;
  overflow: hidden;
  white-space: pre-line;
}
#chat-pane .rowLeft .bubble {
  background: #fafafa;
  border-top-left-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 0px;
  margin-bottom: 14px;
}
#chat-pane .rowRight .bubble {
  background: #e9fafc;
  border-top-left-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 10px;
  margin-bottom: 14px;
}
#chat-pane .rowLeft {
  flex-direction: row;
  align-items: flex-start;
}
#chat-pane .rowRight {
  flex-direction: row-reverse;
  align-items: flex-start;
}
#chat-pane .triangleCornerRight {
  width: 0;
  height: 0;
  border-right: 15px solid transparent;
  border-top: 15px solid #e9fafc;
  transform: rotate(270deg);
  margin-bottom: 14px;
}
#chat-pane .triangleCornerLeft {
  width: 0;
  height: 0;
  border-right: 15px solid transparent;
  border-top: 15px solid #fff;
  transform: rotate(180deg);
  margin-bottom: 14px;
}
#chat-pane .message-avatar {
  cursor: pointer;
  margin-bottom: 14px;
}
#chat-pane .image-file {
  cursor: pointer;
  object-fit: cover;
  height: 200px;
  max-width: 80%;
}

#chat-container {
  position: relative;
  width: 100%;
}

#chat-container #chat-header {
  width: 100%;
}
#chat-container #chat-header .MuiToolbar-root {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

#chat-container #chat-loader {
  margin: 0;
}

#chat-container > .MuiCard-root {
  display: flex;
  flex-direction: column;
}

#chat-container > .MuiCard-root > .MuiList-root {
  height: calc(100vh - 290px);
  overflow-y: auto;
  background-color: #8bdfea;
}

#chat-pane {
  height: 100%;
}

@media (min-width: 600px) {
  #chat-container > .MuiCard-root > .MuiList-root {
    height: calc(100vh - 246px);
  }
}

#chat-footer {
  padding: 12px 4px;
  height: 84px;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
}

@media (min-width: 600px) {
  #chat-footer {
    padding: 20px;
  }
}

#chat-footer .MuiToolbar-root section {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#chat-actions-button {
  min-width: 170px;
  max-width: 170px;
  flex: 0.6;
  padding: 4px 14px;
}
@media (min-width: 600px) {
  #chat-actions-button {
    min-width: 200px;
    max-width: 200px;
    margin-right: 14px;
  }
}

#chat-actions-button.typing {
  min-width: 46px;
  max-width: 46px;
}

#chat-actions-button.typing span.text {
  display: none;
}

#chat-input {
  width: 100%;
  display: flex;
  position: relative;
}

#chat-input .MuiFormControl-root,
#chat-send-button {
  flex: 1;
}

#chat-input .MuiOutlinedInput-multiline {
  min-width: 115px;
  padding: 14px 42px 14px 24px;
  flex-grow: 1;
}

#chat-send-button {
  padding: 5px 5px 0 0;
  margin: 0;
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 5px;
}

#chat-send-button:not([disabled]) {
  background: transparent;
  color: #01c6d9;
}
.MuiIconButton-root.Mui-disabled:hover {
  color: rgba(0, 0, 0, 0.26);
}

#chat-input-file {
  display: none;
}

.chat-input-hidden {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  opacity: 0;
}

#chat-input-file-button {
  width: 42px;
  height: 42px;
}

@media (min-width: 600px) {
  #chat-input-file-button {
    margin-left: 24px;
  }
}
.chat-image-viewer-container {
  width: 100%;
  height: 100%;
  outline: 0;
  overflow: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-image-viewer-image {
  cursor: pointer;
  width: 90%;
  height: auto;
  margin: 5%;
  vertical-align: middle;
}

.chat-messageview-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.chat-messageview-wrap.highlight {
  z-index: 100;
}
.chat-messageview-bubble-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  min-width: 300px;
  justify-content: flex-start;
}
.chat-messageview-bubble-container.sender {
  justify-content: flex-end;
}

#chat-message-menu .MuiMenuItem-root .MuiSvgIcon-root {
  margin-right: 10px;
}

.chat-date-row.MuiListSubheader-root {
  text-align: left;
  font-size: 14px;
  color: white;
  font-weight: 600;
  position: static;
}

.chat-message-admin {
  display: flex;
}
.chat-message-admin .MuiTypography-root {
  flex: 1;
  font-weight: 600;
}

.chat-message-admin .MuiIconButton-root {
  width: 10px;
  height: 10px;
}
.chat-message-admin .MuiIconButton-root.red .MuiSvgIcon-root {
  color: red;
}

.chat-message-admin .MuiIconButton-root.red span {
  font-size: 12;
  font-weight: 600;
  color: red;
}

#chat-dimmer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1000px;
  background-color: #00000099;
  z-index: 9;
}

.messageView .MuiButton-root p {
  margin: 7px 0 0 7px;
}

.messageView .MuiButton-root.rsvp-selected {
  background-color: green;
}

.messageView .rsvp-buttons-container {
  text-align: center;
}
.messageView .rsvp-buttons-container > * {
  margin: 7px;
}
/* widgets */
.clearfix {
  clear: both;
}
.widget-container .table {
  display: flex;
  border: 1px solid #d3dce2;
  flex-direction: row;
  overflow-y: auto;
  max-height: 200px;
  overflow-x: auto;
  scroll-behavior: smooth;
}
.widget-container .grid-header {
  display: flex;
  border: 1px solid #d3dce2;
  min-width: 120px;
  align-items: center;
  padding-left: 4px;
}
.widget-container .grid-column {
  display: inline-block;
}
.widget-container .grid-date {
  display: flex;
  border: 1px solid #d3dce2;
  border-left-width: 0px;
  min-width: 70px;
  align-items: center;
  justify-content: center;
}
.widget-container .top-row {
  border-top-width: 1px;
  min-height: 120px;
}
.widget-container .grid-row {
  display: flex;
}
.widget-container .table.by-row {
  flex-direction: column;
}

.widget-container .table.by-row .grid-row {
  border-bottom: 1px solid #d3dce2;
  display: flex;
  flex-direction: row;
}

.widget-container .table.by-row .grid-column:not(.grid-header):first-child {
  border-left: 0;
  justify-content: flex-start;
}
.widget-container .table.by-row .grid-column:not(.grid-header):last-child {
  justify-content: flex-end;
}

.widget-container .table.by-row .grid-column {
  flex: 1;
  display: flex;
  padding: 1px 7px;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #d3dce2;
}
.widget-container .table.by-row .grid-column.grid-header {
  line-height: 36px;
  border-left: 1px solid #d3dce2;
}

.widget-container .total-row {
  display: flex;
  flex-direction: row;
  min-height: 30px;
  border-top-width: 0px;
}
.widget-container .input-row {
  display: flex;
  flex-direction: row;
  min-height: 50px;
  border-top-width: 0px;
}
.widget-container .friend-row {
  display: flex;
  flex-direction: row;
  min-height: 35px;
  border-top-width: 0px;
}
.widget-container .input {
  cursor: pointer;
  background-color: #eff0f1;
}
.widget-container .checked {
  background-color: #86dde8;
}
.widget-container .avatar {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-bottom: 0px;
}
.widget-container .column {
  flex-direction: column;
}
.widget-container .header-column {
  display: inline-block;
}
.widget-container .date-column {
  display: inline-block;
}
.widget-container .click-able {
  cursor: pointer;
}

/* signup form */
.signup-form-widget .table {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.signup-form-widget .grid-row {
  display: flex;
  border: 1px solid #d3dce2;
  border-right-width: 0px;
  border-top-width: 0px;
  min-height: 40px;
  align-items: flex-start;
  width: fit-content;
}
.signup-form-widget .top-row {
  border-top-width: 1px;
}
.signup-form-widget .grid-cell {
  border: 0px solid #d3dce2;
  border-right-width: 1px;
  padding: 2px;
  min-height: 50px;
}
.signup-form-widget .item-column {
  width: 140px;
  display: flex;
  align-items: center;
}
.signup-form-widget .option-cell {
  font-size: 12px;
  font-weight: 400, bold;
  width: 120px;
  margin-left: 4px;
  margin-right: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.signup-form-widget .profile-column {
  text-align: center;
  min-width: 120px;
  display: flex;
  align-items: center;
}
.signup-form-widget .check-column {
  text-align: center;
  min-width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.signup-form-widget .click-able {
  cursor: pointer;
}
.signup-form-widget .avatar {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-bottom: 0px;
}
.signup-form-widget .checked {
  background-color: #86dde8;
}
/* invitation */
.invitation-widget .top-row .MuiTypography-root {
  display: inline;
}
.invitation-widget .grid-row .grid-cell .profile-avatar {
  margin: 7px 0 0 7px;
}

/* carousel slider */
.carousel {
  width: 100%;
  overflow: hidden;
  margin: 14px 0 0;
}
.carousel,
.carousel .slick-slide {
  height: 320px;
}

.carousel .slick-track {
  width: 9999px !important;
}

.carousel .slick-list {
  width: 100%;
  cursor: grab;
}
.carousel .slick-slide {
  transform: scale(0.95);
  transition: transform ease-in 0.4s;
  float: left;
}
.carousel .slick-slide.slick-active {
  transform: scale(1);
}

.carousel .slick-slide .MuiCard-root {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  cursor: grab;
}

.carousel .slick-slide .MuiCardMedia-root {
  height: 320px;
  filter: brightness(0.92);
  cursor: grab;
}

.carousel .slick-slide:hover .MuiCardMedia-root {
  filter: brightness(0.96);
}

.carousel .slick-slide .MuiCardContent-root {
  background: rgba(0, 0, 0, 0.65);
  color: white;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 7px 14px;
  user-select: none;
}

.carousel .slick-prev,
.carousel .slick-next {
  display: none !important;
}

@media (min-width: 600px) {
  .carousel,
  .carousel .slick-slide {
    height: 300px;
    width: 260px;
  }
  .carousel .slick-slide .MuiCardMedia-root {
    height: 300px;
  }
  .carousel.fullWidth {
    width: 90%;
    margin: 14px 5% 0;
  }
  .carousel.fullWidth .slick-slide {
    transform: scale(0.98);
  }
}
@media (min-width: 960px) {
  .carousel,
  .carousel .slick-slide {
    height: 360px;
    width: 420px;
  }
  .carousel .slick-slide .MuiCardMedia-root {
    height: 360px;
  }
}

.carousel .MuiCardContent-root p {
  margin: 0;
}

/* checkout and payment */
#checkout section,
#payment section {
  border-radius: 4px;
  border-width: 2px;
  border-color: #fafafa;
  background-color: #fdfbfd;
  padding: 14px 0 24px;
  border-bottom: 1px solid #eee;
}

#checkout #cart {
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  padding: 0 24px 0;
  overflow-y: auto;
  max-height: 240px;
  background: white;
}

#checkout .cart-row {
  padding: 7px 24px;
  border-bottom: 1px solid #f7f7f7;
}

#checkout .cart-row img,
#checkout .cart-row p,
#checkout .cart-row div {
  display: inline;
  vertical-align: middle;
}
#checkout .cart-row p {
  padding: 0 14px;
}

#checkout .cart-row img {
  max-height: 30px;
  border-radius: 10px;
}

#payment .MuiInputBase-root input {
  padding: 14px 24px;
}

.MuiListItem-container {
  list-style: none;
}

#collect-money .MuiCardContent-root {
  text-align: center;
}

#payment-modal {
  width: 100%;
  max-width: 640px;
  margin: 5% auto;
  overflow-y: auto;
}
