html {
  background-color: #fff;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  text-align: center;
}

input::-webkit-input-placeholder {
  font-size: 16px;
}

body,
#root,
.page-wrapper {
  height: 100%;
  background-color: #fcfcfc;
}

.ujama-address-map-maker-icon {
  color: #fd6c6c;
}

.ujama-address-suggestion-icon {
  margin-right: 8px;
}

.ujama-address-search-bar-container {
  width: 90%;
  max-width: 500px;
  margin: 40px auto 0;
  padding-bottom: 10px;
}

.ujama-address-search-input-container {
  position: relative;
}

.ujama-address-search-input,
.ujama-address-search-input:focus,
.ujama-address-search-input:active {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  border: honeydew;
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border-radius: 2px;
  outline: none;
}

.ujama-address-clear-button,
.ujama-address-clear-button:active,
.ujama-address-clear-button:focus {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  color: #999;
}

.ujama-address-autocomplete-container {
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 2px 2px;
}

.ujama-address-suggestion-item,
.ujama-address-suggestion-item--active {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}

.ujama-address-suggestion-item--active {
  background-color: #fafafa;
}

.ujama-address-dropdown-footer {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
}

.ujama-address-dropdown-footer-image {
  display: inline-block;
  width: 150px;
}

.ujama-address-spinner {
  color: #18bc9c;
  font-size: 30px;
}

.ujama-address-error-message {
  color: red;
}

.ujama-address-geocode-result-header {
  font-size: 20px;
  color: #222222;
}

@media (max-width: 480px) {
  .autocomplete-container {
    text-align: left;
  }
}

@media (min-width: 768px) {
  .alert {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    -webkit-animation: fadein 0.75s;
    -moz-animation: fadein 0.75s;
    -ms-animation: fadein 0.75s;
    -o-animation: fadein 0.75s;
    animation: fadein 0.75s;
  }

  .btn {
    margin-top: 1.5rem;
  }

  .form-group {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
    width: 50%;
  }
}
