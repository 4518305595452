.img-holder {
  padding: 10px;
}

.media {
  width: 100%;
}
.flex-hold {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.media img {
  max-height: 200px;
  max-width: 200px;
  cursor: pointer;
}
